 <template >
  <div>
    <HeaderMenu />
    <div class="error-404">
      <div class="e404-cont">
        <img src="../assets/la-vaca-lola.png" alt="vaca" class="vaca pc" />
        <div class="info">
          <p class="titulo">
            UPS...
            <span class="s-p"> ALGO SALIÓ MAL </span>
          </p>
          <img src="../assets/la-vaca-lola.png" alt="vaca" class="vaca mo" />
          <p class="parrafo p1">
            No hemos logrado encontrar el contenido que buscas.
          </p>
          <p class="parrafo">
            Utiliza el siguiente enlace para encontrar
            <a href="/">el camino de regreso.</a>
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import HeaderMenu from "../components/fixedItems/HeaderMenu.vue";
import Footer from "../components/fixedItems/Footer.vue";
export default {
  name: "Error404",
  data() {
    return {
      status: "error",
      message: "",
    };
  },
      metaInfo(){
        return{
            title: "Error 404",
            titleTemplate: "%s | Agriga de México",
            htmlAttrs: {
            lang: "es",
            },
            meta: [
            { charset: "utf-8" },
            {
                name: "description",
                content: "La URL del sitio se ha eliminado, ¡pero no te preocupes! Todavía podemos ayudarte a encontrar los productos que necesitas. ",
            },
            { name: "viewport", content: "width=device-width, initial-scale=1" },
            ],            
        };
      },
  components: {
    HeaderMenu,
    Footer,
  },
 /* watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Error - Agriga de México"; 
        document.meta.description = "Error - Agriga de México"; 
      },
    },
  },*/
};
</script>
<style scoped>
.e404-cont {
  width: 78.896vw;
  display: flex;
  margin: auto;
  padding: 4.031vw 0 4.063vw;
  justify-content: space-between;
}
.error-404 .vaca {
  width: 35.052vw;
  height: auto;
}
.error-404 .vaca.pc {
  display: block;
}
.error-404 .vaca.mo {
  display: none;
}
.error-404 p.titulo {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 4.531vw;
  line-height: 2.417vw;
  color: #3ebdb1;
  margin: 0 0 3.698vw;
  width: 17.4vw;
}
.error-404 p.titulo span.s-p {
  font-size: 1.927vw;
  line-height: 2.313vw;
}
.error-404 p.parrafo {
  font-weight: 400;
  font-size: 1.302vw;
  line-height: 129.5%;
  color: #929292;
  margin: 0;
  font-family: "Helvetica World Regular";
}
.info {
  padding-top: 7.25vw;
}
.error-404 p.parrafo a {
  color: #fe9128;
  text-decoration-color: transparent;
}
.error-404 p.parrafo.p1 {
  margin: 0 0 1.4vw;
}

@media (max-width: 768px) {
  .e404-cont {
    flex-direction: column;
    padding: 20.531vw 0 17.15vw;
  }
  .error-404 .vaca.pc {
    display: none;
  }
  .error-404 .vaca.mo {
    display: block;
  }
  .error-404 p.titulo {
    font-size: 21.014vw;
    line-height: 11.014vw;
    margin: 0 0 13.285vw;
    width: 80.4vw;
  }
  .error-404 p.titulo span.s-p {
    font-size: 8.937vw;
    line-height: 4.937vw;
  }
  .error-404 .vaca {
    width: 76.087vw;
    height: auto;
    margin: 0 0 13.285vw;
  }
  .error-404 p.parrafo {
    font-size: 3.382vw;
  }
  .error-404 p.parrafo.p1 {
    margin: 0 0 6.4vw;
  }
}
</style>